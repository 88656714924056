import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/components/articles-layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`Git: Understanding version control`}</h1>
    <p>{`Version control systems are tools to keep track of changes to source code or other collections of files and folders. They help to track the changes of documents, they make it easy collaborating with someone else.`}</p>
    <p>{`Version Control track changes in a series of snapshots and they contain the entire state of files or folders within a directory. They also add metadata that contains things like who created the snapshot, messages, changes, etc.`}</p>
    <p>{`Version control is useful because it let you look at old snapshots of a project, keep a log of changes, work on different branches, see who wrote a specific line or who added a change and more.`}</p>
    <h2>{`Git data model`}</h2>
    <p>{`Git has a data model that enables features of version control such as keeping track of changes in history, allowing branches and collaboration with other folks.`}</p>
    <h3>{`Snapshots`}</h3>
    <p>{`A snapshot is what we know as a commit. It contains the history of files, folders and metadata. A snapshot is the top-level tree/directory that is being tracked.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` We can track a top-level tree with the command `}<inlineCode parentName="p">{`git init`}</inlineCode></p>
    </blockquote>
    <p>{`We can think of a snapshoot as being built like this:`}</p>
    <pre><code parentName="pre" {...{}}>{`type commit = struct {
    parent: array<commit>
    author: string
    message: string
    snapshot: tree
}
`}</code></pre>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` this is pseudo-code`}</p>
    </blockquote>
    <p>{`In Git terminology, a file is called a "blob" because it's just a bunch of bytes. We can think of blobs as an array of bytes:`}</p>
    <pre><code parentName="pre" {...{}}>{`type blob = array<bytes>
`}</code></pre>
    <p>{`A directory is called a tree. A tree, maps names to trees or blobs which means that a directory can contain other directories.`}</p>
    <pre><code parentName="pre" {...{}}>{`type tree = map<string, tree | blob>
`}</code></pre>
    <h3>{`Objects and content-addressing`}</h3>
    <p>{`Even though we name each file, directory and commit differently, they are all objects. And git stores all this data as objects inside a content address store.`}</p>
    <pre><code parentName="pre" {...{}}>{`objects = map<string, objects>
`}</code></pre>
    <p>{`This means that the core of git is a simple key-value data store. You can insert any object into your git storage and git will store it with a unique key.`}</p>
    <p>{`This unique key is the `}<a parentName="p" {...{
        "href": "https://en.wikipedia.org/wiki/SHA-1"
      }}>{`SHA-1 hash`}</a>{` of that object and it allows us to access the object with that key.`}</p>
    <blockquote>
      <p parentName="blockquote"><strong parentName="p">{`Note:`}</strong>{` Using a hash allows us to take a big piece of data and turn it into a short string`}</p>
    </blockquote>
    <p>{`Taking the example from that `}<inlineCode parentName="p">{`git log`}</inlineCode>{` that I have shown before:`}</p>
    <pre><code parentName="pre" {...{}}>{`* 8f0dede (HEAD -> twitter, upstream/master, master) Add twitch to config example (#1653)
| * a03b68c (origin/twitch, twitch) Add twitch to config example
|/
* 2c9b684 Migrate helper and connector tests to pytest (#1648)
* af00ac9 Fix typo on documentation - Gitter (#1652)
`}</code></pre>
    <p>{`If we want to access or reference the snapshot `}<em parentName="p">{`"Migrate helper and connector tests to pytest"`}</em>{` I can reference the first seven characters of that object hash: `}<inlineCode parentName="p">{`af00ac9`}</inlineCode>{`.`}</p>
    <h2>{`Git Branches`}</h2>
    <p>{`We can look at snapshots as to what we call  `}<em parentName="p">{`commits`}</em>{` in git, they contain all the changes done inside the directory. Each snapshot/commit points back to the one that precedes it.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "565px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/95d438a8a53b744923cfcd860b93b04b/07eba/main_branch.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "11.042944785276074%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAACCAIAAADXZGvcAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAT0lEQVQI1z2MQQ6AIBADeUP7BPSkBOlG0f9/jHWNJD1MmmnTSvuSaRXqkEEOJeCCGnRAO/Sgicq/70mTFlqh9VBfm3L1jCPfb9DtfWhzMgA25xGxvTeYAQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "main branch",
            "title": "main branch",
            "src": "/static/95d438a8a53b744923cfcd860b93b04b/07eba/main_branch.png",
            "srcSet": ["/static/95d438a8a53b744923cfcd860b93b04b/222b7/main_branch.png 163w", "/static/95d438a8a53b744923cfcd860b93b04b/ff46a/main_branch.png 325w", "/static/95d438a8a53b744923cfcd860b93b04b/07eba/main_branch.png 565w"],
            "sizes": "(max-width: 565px) 100vw, 565px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Since all these snapshots point back to the previous one, we can this `}<em parentName="p">{`history/line`}</em>{` a branch. Usually, you will always have the main branch when you start tracking the changes of a folder with git.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "565px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/de1b94cb3a30f2879a7afd7dad7d49dd/07eba/git_branch.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "30.67484662576687%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAk0lEQVQY04WQ7QqDMAxF+womDzDch9OyyXpTNx1s7/9YC7cgKgwhP5KmJzkkXNSWcVaLai8BBIMgMZmq1Ky/lQib+qR2VxsFT8FIOAs+VYps7cO+5KHWqh3VaibOT4Id2LEbzWuSHUtPDq5DheYf7INd8k3bTOFiPjB6wVdSXu8Pm1P5eSJ3XtV6juj43rJVvGbkByLQNAwJaFeoAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "git branch",
            "title": "git branch",
            "src": "/static/de1b94cb3a30f2879a7afd7dad7d49dd/07eba/git_branch.png",
            "srcSet": ["/static/de1b94cb3a30f2879a7afd7dad7d49dd/222b7/git_branch.png 163w", "/static/de1b94cb3a30f2879a7afd7dad7d49dd/ff46a/git_branch.png 325w", "/static/de1b94cb3a30f2879a7afd7dad7d49dd/07eba/git_branch.png 565w"],
            "sizes": "(max-width: 565px) 100vw, 565px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Usually, this branch is the one that folks use to keep the latest version of a project. The powerful thing of git, is that it allows you to branch off from a specific snapshot/commit, so you can work with different branches of a commit and then merge them.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2014f57c13356e1c00fd4d0305da4d39/c929c/gitcommit.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.331288343558285%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABVklEQVQY00VQCW6DMBDkHwk5MMbgA9825giUNEkjtZX6/890k1SqNLLW1szsjDPL1KmTlBleaIFlhXhRc0WdazrCLWLa6Mn7lbFYFBwDodJl2cKQ51XWW7OkJ8MuVs/WzC6uQvbWL0qN0b/XtW3bhJDYbvF2Wz6BN5vyIb5O7nP1Id7m5XuY7uv6k9Itddd5/gphlXJo2x62YayA/dT/Ic9xlpy6jLbv3vr4McR7Hy8hnGO89P2t664wQGaI8EJdG4wfmSHI8UizPeJlQSpE0EGgQ4ugc8FL1EIxGKpKHQ4UqC/Ba/gXEy4V46JhlNgaK0H9w77RhJqqkk3jKA2EGADnEa5QARZsNugRW0h1m1SyaZTrIE6u9E2t90ojqYEqRAedGQuUeq0nIRJYgDv03+1Ipky8DKYLo/NnH1YuEpC0HJ2B354A1s7wAvvhtHYBMXw++EL4X4mKV5PlycXDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gitcommit",
            "title": "gitcommit",
            "src": "/static/2014f57c13356e1c00fd4d0305da4d39/a6d36/gitcommit.png",
            "srcSet": ["/static/2014f57c13356e1c00fd4d0305da4d39/222b7/gitcommit.png 163w", "/static/2014f57c13356e1c00fd4d0305da4d39/ff46a/gitcommit.png 325w", "/static/2014f57c13356e1c00fd4d0305da4d39/a6d36/gitcommit.png 650w", "/static/2014f57c13356e1c00fd4d0305da4d39/e548f/gitcommit.png 975w", "/static/2014f57c13356e1c00fd4d0305da4d39/c929c/gitcommit.png 1218w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Here you can see the log of a project that I work on. You can see that each `}<inlineCode parentName="p">{`*`}</inlineCode>{` is a snapshot, each line shows the `}<em parentName="p">{`path`}</em>{` that each snapshot took, you can also see the times that a branch was created and then merged back into the main one.`}</p>
    <h2>{`References and Repositories`}</h2>
    <p>{`Now that we know that git objects can be identified by their SHA-1 hash and that snapshots live inside branches we can explore more about what is a reference inside git. `}</p>
    <p>{`A reference is a human-readable label to refer to a particular snapshot in history instead of using the whole SHA-1 hash.`}</p>
    <pre><code parentName="pre" {...{}}>{`references = map<string, string>
`}</code></pre>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "650px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/static/2014f57c13356e1c00fd4d0305da4d39/c929c/gitcommit.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "42.331288343558285%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAAsTAAALEwEAmpwYAAABVklEQVQY00VQCW6DMBDkHwk5MMbgA9825giUNEkjtZX6/890k1SqNLLW1szsjDPL1KmTlBleaIFlhXhRc0WdazrCLWLa6Mn7lbFYFBwDodJl2cKQ51XWW7OkJ8MuVs/WzC6uQvbWL0qN0b/XtW3bhJDYbvF2Wz6BN5vyIb5O7nP1Id7m5XuY7uv6k9Itddd5/gphlXJo2x62YayA/dT/Ic9xlpy6jLbv3vr4McR7Hy8hnGO89P2t664wQGaI8EJdG4wfmSHI8UizPeJlQSpE0EGgQ4ugc8FL1EIxGKpKHQ4UqC/Ba/gXEy4V46JhlNgaK0H9w77RhJqqkk3jKA2EGADnEa5QARZsNugRW0h1m1SyaZTrIE6u9E2t90ojqYEqRAedGQuUeq0nIRJYgDv03+1Ipky8DKYLo/NnH1YuEpC0HJ2B354A1s7wAvvhtHYBMXw++EL4X4mKV5PlycXDAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="a" {...{
            "className": "gatsby-resp-image-image",
            "alt": "gitcommit",
            "title": "gitcommit",
            "src": "/static/2014f57c13356e1c00fd4d0305da4d39/a6d36/gitcommit.png",
            "srcSet": ["/static/2014f57c13356e1c00fd4d0305da4d39/222b7/gitcommit.png 163w", "/static/2014f57c13356e1c00fd4d0305da4d39/ff46a/gitcommit.png 325w", "/static/2014f57c13356e1c00fd4d0305da4d39/a6d36/gitcommit.png 650w", "/static/2014f57c13356e1c00fd4d0305da4d39/e548f/gitcommit.png 975w", "/static/2014f57c13356e1c00fd4d0305da4d39/c929c/gitcommit.png 1218w"],
            "sizes": "(max-width: 650px) 100vw, 650px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy"
          }}></img>{`
  `}</a>{`
    `}</span></p>
    <p>{`Let's look at the image from that `}<inlineCode parentName="p">{`git log`}</inlineCode>{` that I used before. You can see things such as:`}</p>
    <ul>
      <li parentName="ul"><inlineCode parentName="li">{`HEAD`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`upstream/master`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`master`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`refs/stash`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`origin/ibm-watson`}</inlineCode></li>
      <li parentName="ul"><inlineCode parentName="li">{`...`}</inlineCode></li>
    </ul>
    <p>{`These are all references that reference something. The `}<inlineCode parentName="p">{`HEAD`}</inlineCode>{` means that this is the current commit that we are working on, `}<inlineCode parentName="p">{`master`}</inlineCode>{` refers to the main branch while `}<inlineCode parentName="p">{`upstream/master`}</inlineCode>{` is the main branch from the original repository for this project.`}</p>
    <p>{`So what is a repository?`}</p>
    <p>{`Putting very simply, a git repository contains all the objects and references. These repositories live inside our disk, but you could use services like GitHub, Gitlab or Bitbucket to save your repositories on these services. They also allow better collaboration with other folks and the reason why they become so popular.`}</p>
    <h2>{`The Stating Area`}</h2>
    <p>{`The last thing that is worth to mention is that git has this concept of stating area. This is where you add files to this staging area to keep track of changes done and include them in your next snapshot.`}</p>
    <p>{`By using the command `}<inlineCode parentName="p">{`git status`}</inlineCode>{`, git tells you if you have any files that are currently untracked inside a project - these files will not be included on your next snapshot.`}</p>
    <p>{`To include these files you can run the command `}<inlineCode parentName="p">{`git add <name of the file>`}</inlineCode>{` or `}<inlineCode parentName="p">{`git add .`}</inlineCode>{` to include all untracked files into the staging area.`}</p>
    <p>{`After running that command you can type `}<inlineCode parentName="p">{`git status`}</inlineCode>{` again and you will see your file names in green and bellow the text: `}<em parentName="p">{`Changes to be committed:`}</em></p>
    <p>{`To create a new snapshot with these files in staging you need to run the command `}<inlineCode parentName="p">{`git commit`}</inlineCode>{` which will open your text editor for you to choose a name for that snapshot. Alternatively, you can run the command `}<inlineCode parentName="p">{`git commit -m "<message>"`}</inlineCode>{` to do it in a single command.`}</p>
    <hr></hr>
    <p><strong parentName="p">{`References:`}</strong></p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://missing.csail.mit.edu/2020/version-control/"
        }}>{`Official course notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://includejs.dev/notes/missing-semester-06/"
        }}>{`IncludeJS: Eva's lesson notes`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://alexwlchan.net/a-plumbers-guide-to-git/1-the-git-object-store/"
        }}>{`The git object store`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://git-scm.com/book/en/v2"
        }}>{`Book: Pro Git - Free`}</a></li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "https://jwiegley.github.io/git-from-the-bottom-up/"
        }}>{`Git from the Bottom up`}</a></li>
    </ul>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      